<template>
  <div id="app">
    <div class="menu">
      <div class="logo">
        <img src="./img/logo.png" alt="">
      </div>
      <div class="dropdown">
        <a href="https://www.banpo.com.cn/p-lianxiwomen.html" v-on:mouseover="changeActive($event)" v-on:mouseout="removeActive($event)">技术支持</a>
      </div>
    </div>
    <div class="download">
      <div class="download_text">
        <div>欢迎使用《{{ productName }}》</div><br>
<!--        <div class="download_text_a">现在能够轻松地在 Mac 与 Windows 上运行。请按照下列步骤快速启动和运行。</div>-->
        <div class="download_text_a">
          <p>
            《{{productName}}》是由计算机替读者预读、解析文献，以其独有的文本内容可视化语义脑图交互手段：
          </p>
          <ol>
            <li>解析文献内容中的主题概念及其关联关系；</li>
            <li>主题概念的层次结构化呈现；</li>
            <li>文献内容知识点互为链接指引的全超链关联；</li>
            <li>发现并揭示互不直接关联的知识点之间可能潜在的隐性知识链路。</li>
          </ol>
          <p>
            既可以实时无缝嵌入图书馆现有的文献数据库，也可以通过个人积累文献加载方式，围绕读者需求（搜索提问、兴趣点）提供文献内容的可视化语义脑图交互，既能辅助读者启发式发散思维，又能节省读者阅读时间成本。由此，赋予读者文献阅读一种全新的智慧导读体验。
          </p>
          <p>
            初次使用请下载<a :href="manual" target="_blank"><strong>用户手册</strong></a>
          </p>
        </div>
      </div>
      <div class="download_link">
        <a v-for="button in buttons" :href="button.href" target="_blank">
          <span>{{button.name}}</span><i class="el-icon-download"></i>
        </a>
      </div>
    </div>
<!--    <div class="videos">
      <div class="videos_a">
      <span>安装 Windows 版本《{{ productName }}》</span>
      </div>
      <div class="tutorial"></div>
      <div class="videos_b">
        <span>安装MAC版本《{{ productName }}》</span>
      </div>
      <div class="tutorial"></div>
      <div class="videos_c">
        <span>安装Linux版本《{{ productName }}》</span>
      </div>
      <div class="tutorial"></div>
    </div>-->
<!--    <div class="help">
      <span class="help_text">使用期间需要帮助？</span> <br><br>
      <span class="help_text_a">访问我们的“技术支持”页获取有用的信息。</span>
    </div>-->
  </div>
</template>
<script>
import axios from 'axios'

// 产品名称
const productName = typeof window.PRODUCT_NAME !== 'undefined' ? window.PRODUCT_NAME : '智慧导读'
const baseUri = typeof window.API_BASE_URL !== 'undefined' ? window.API_BASE_URL : '/api'
const flavor = typeof window.PRODUCT_FLAVOR !== 'undefined' ? window.PRODUCT_FLAVOR : 'default'

const title = '《' + productName + '》下载'

if (window.BELONG) {
  document.title = window.BELONG + title
} else {
  document.title = title
}

const manual = 'docs/用户手册.pdf'

export default {
  data () {
    return {
      productName: productName,
      manual: manual,
      buttons: []
    }
  },
  methods: {
    changeActive ($event) {
      $event.currentTarget.className = 'text'
    },
    removeActive ($event) {
      $event.currentTarget.className = ''
    },
    changeLogin ($event) {
      $event.currentTarget.className = 'login login_mouse'
    },
    removeLogin ($event) {
      $event.currentTarget.className = 'login'
    },
    changeDownLoad ($event) {
      $event.currentTarget.className = ' download_mouse'
    },
    removeDownLoad ($event) {
      $event.currentTarget.className = 'download_link'
    }
  },
  mounted () {
    const now = new Date()
    // uri参数缓存
    const version = new Date().setHours(now.getHours(), now.getMinutes(), 0, 0).toString()
    axios.get(baseUri + '/versions/sorted?_=' + version + '&flavor=' + flavor).then((res) => {
      // 如果下载地址为外链
      const homeUri = window['download-origin'] || ''
      // 各平台下载链接
      const caches = {}
      // 按钮列表
      const buttons = []

      res.data.items.forEach(function (version) {
        const flavor = version.flavor?.name || 'default'
        const baseUri = homeUri + '/download/flavor/' + flavor + '/' + version.name

        // 各版本下的构建
        version.assets.forEach(function (asset) {
          const platform = asset.platform
          const filetype = asset.filetype

          switch (filetype) {
            case '.exe':
            case '.dmg': break
            default: return
          }

          if (caches.hasOwnProperty(platform)) {
            return
          }

          let name

          switch (platform) {
            case 'osx_64': name = '下载MacOS版本'; break
            case 'windows_64': name = '下载Windows版本'; break
          }

          const button = { href: baseUri + '/' + platform + '/' + asset.name, name: name }

          caches[platform] = button
          buttons.push(button)
        })
      })

      this.buttons = buttons
    })
  }
}
</script>
<style lang="scss" >
.menu{
  display: flex;
  justify-content: space-between;
  padding: 0 300px ;
  border-bottom:1px solid rgba(121, 116, 116, 0.205);
    img{
      height: 50px;
    }
    .dropdown{
      display: flex;
      justify-content: space-evenly;
      list-style-type: none;
      cursor:pointer;
      font-size: 20px;
      padding-bottom:20px ;
      position: relative;
      top: 10px;
        .text{
          border-bottom: 2px solid red;
         }
    }

    .el-icon-user{
      font-size:150% ;
    }
}
.download{
  max-width: 1300px;
  margin: 90px auto 0;
  padding-bottom: 40px;
   //border-bottom:1px solid rgba(121, 116, 116, 0.205);
  .download_text{
    font-size: 36px;
    text-align: center;
    .download_text_a{
      max-width: 768px;
      font-size: 18px;
      text-align: left;
      margin: -20px auto 0;
      p {
        text-indent: 2em;
      }
    }
  }
  .download_link{
    font-size: 16px;
    text-align: center;
    margin-top:40px ;

    :hover {
      background: black;
      color: white;
    }
    span{
      position: relative;
      top: -6px;
    }
    a{
      text-decoration: none;
      color: black;
      margin-left:20px ;
      padding: 22px 20px 15px;
      border: 2px solid black;
      border-radius:100px
    }
    .el-icon-download{
      font-size: 220%;
    }
  }
}
.text-summary {
  max-width: 1300px;
  margin: 0 auto;
}
.videos{
  max-width: 1300px;
  margin: 20px auto 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 36px;
  padding-bottom: 30px;
   border-bottom:1px solid rgba(121, 116, 116, 0.205);
   .videos_b,.videos_c{
    margin-top: 30px;
   }
  .tutorial{
    margin-top: 15px;
    height: 400px;
    width: 600px;
    background: rgba(0, 0, 0, 0.281);
  }

}
.help{
  margin-top: 20px;
  text-align: center;
  margin-bottom: 50px;
  .help_text{
    font-size: 36px;
  }
  .help_text_a{
      font-size: 18px
  }
}
a:visited{
  color:black;
}
a{
  text-decoration:none;
}
</style>
